import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const BaseButton = styled(Link)`
  background: ${props => props.theme.colors.black};
  color: white;
  position: relative;
  padding: 1rem;
  border-radius: 4rem;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0.2);
  margin: 0 0 0 0.5rem;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s all;

  @media (hover: none) {
    background: ${props => props.theme.colors.black} !important;
  }
`

const Button = ({ children, ...props }) => {
  return <BaseButton {...props}>{children}</BaseButton>
}

export default Button
